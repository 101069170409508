@import "src/styles/mixins";
@import "src/styles/variables";

.container {
  min-height: 35px;
  background: $white;
  display: flex;
  overflow-x: auto;
  @include customScroll();
  align-self: flex-end;
  width: 100%;

  .goBackButton {
    img {
      transform: rotate(90deg);
    }

    &:hover {
      opacity: 1;
    }
  }

  .empty {
    width: 30px;
  }

  .folder {
    cursor: pointer;
    height: 100%;
    padding: 4px 12px;
    border-right: 1px solid $borderColor;
    display: flex;
    align-items: center;

    span {
      @include font();
      white-space: nowrap;
    }

    &:hover {
      span {
        color: $elementsColor;
      }
    }
  }
}
