@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  background: $elementsColor;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: default;
    opacity: 1;
    background: rgba($color: $elementsColor, $alpha: 0.5);
  }

  span {
    @include font($white, 14, 600);
  }
}