@import "src/styles/mixins";
@import "src/styles/variables";

body {
  margin: 0;
  position: relative !important;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  @include font();
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  user-select: none;
}

address,
body,
code,
div,
form,
h1,
h2,
h3,
h4,
header,
html,
i,
img,
label,
li,
object,
p,
span,
summary,
time,
tr,
ul,
button,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 14px;
  vertical-align: baseline;
  background: transparent;
}

ul,
ol {
  list-style: none;
}

body > iframe {
  display: none !important;
}

::-ms-reveal {
  display: none;
}

.appContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: $backgroundColor;
}

.appContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  @include fadeIn();
}