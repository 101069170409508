@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    cursor: pointer;
  }

  .generalButton {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.4;
    }
    &:disabled {
      opacity: 0;
    }
  }

  .dropBoxContainer {
    width: max-content;
    display: none;
    z-index: 2;
    border: 1px solid $borderColor;
    border-radius: 3px;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    box-shadow: $shadow;
    @include customScroll();
  }

  .topDropBoxContainer {
    top: auto;
    bottom: calc(100% + 2px);
  }

  .visibleDropBoxContainer {
    display: block;
  }

  .fullWidthDropBox {
    width: 100%;
    min-width: fit-content;
  }

  .action {
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 16px;
    min-height: 28px;

    span {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: $borderColor;
    }

    &:disabled {
      background: transparent;
      cursor: default;

      span {
        color: $grayTextColor;
      }
    }
  }
}
