@import "./variables.scss";

@mixin font($color: $mainTextColor, $fontSize: 14, $fontWeight: 400) {
  font-size: $fontSize + px;
  color: $color;
  font-weight: $fontWeight;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin changeImgVisibilityByImgState() {
  img[data-img-state="ACTIVE"], img[data-img-state="HOVER"], img[data-img-state="DISABLED"] {
    display: none;
  }

  &:hover:not(:disabled) {
    img[data-img-state="IDLE"], img[data-img-state="ACTIVE"], img[data-img-state="DISABLED"] {
      display: none;
    }
    img[data-img-state="HOVER"] {
      display: block;
    }
  }

  &:active:not(:disabled) {
    img[data-img-state="IDLE"], img[data-img-state="HOVER"], img[data-img-state="DISABLED"] {
      display: none;
    }
    img[data-img-state="ACTIVE"] {
      display: block;
    }
  }

  &:disabled {
    img[data-img-state="IDLE"], img[data-img-state="ACTIVE"], img[data-img-state="HOVER"] {
      display: none;
    }
    img[data-img-state="DISABLED"] {
      display: block;
    }
  }
}

@mixin tablet() {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin noTablet() {
  @media (min-width: 1201px) {
    @content;
  }
}

@mixin customScroll($size: 6, $backgroundThumbColor: $grayTextColor, $backgroundTrackColor: transparent) {
  &::-webkit-scrollbar {
    width: $size + px;
    height: $size + px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: $backgroundThumbColor;
  }

  &::-webkit-scrollbar-track {
    background-color: $backgroundTrackColor;
  }
}

@mixin maxWidth($maxWidth) {
  @media (max-width: ($maxWidth + px)) {
    @content;
  }
}

@mixin fadeIn($duration: 600) {
  animation: fade $duration + ms linear forwards;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin clearButtonStyles() {
  box-sizing: border-box;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

@mixin boxSizing() {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

@mixin getAuthPagesStyles() {
  flex: 1;
  max-height: calc(100vh - var(--titleBarHeight, 0px));
  display: flex;
  background: $elementsColor;
  overflow-y: auto;
  padding: 12px;
  @include customScroll(6, $white);
}
