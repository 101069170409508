@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  padding: 8px;
  margin: 4px 6px;
  flex: 1;
  border: 1px solid $borderColor;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  .title {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}