@import "src/styles/mixins";

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  span {
    text-align: center;
    @include font($fontSize: 28, $fontWeight: 700);
  }
}