@import "src/styles/mixins";
@import "src/styles/variables";

.container {
  display: flex;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;

  .order {
    border-right: 1px solid $borderColor;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50px;
    min-width: 50px;
    width: 100%;

    span {
      overflow: hidden;
      word-wrap: break-word;
    }
  }

  .content {
    flex: 1;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    padding-bottom: 4px;
    overflow: hidden;

    .info {
      width: 100%;
      break-inside: avoid;
      page-break-inside: avoid;

      .header {
        display: flex;
        gap: 12px;
        padding-left: 20px;

        .title {
          @include font($fontSize: 15, $fontWeight: 700);
          overflow: hidden;
          word-wrap: break-word;
          padding: 8px 0 4px 0;
        }

        .estimate {
          @include font($grayTextColor, 13);
          white-space: nowrap;
          padding: 8px 0 4px 0;
        }

        .actions {
          min-width: 20px;
          min-height: 28px;
          margin-left: auto;
          align-self: flex-start;

          & > button {
            padding: 2px;
          }
        }
      }

      .description {
        padding: 0 20px 4px 20px;
        font-size: 13px;
        word-wrap: break-word;
        white-space: break-spaces;
      }
    }
  }

  .contentWithDroppable {
    padding-bottom: 3px;
  }

  .estimateHours {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $borderColor;
    max-width: 90px;
    min-width: 90px;
    padding: 5px;
    width: 100%;
    overflow: hidden;

    span {
      overflow: hidden;
      text-align: center;
      word-wrap: break-word;
      white-space: pre-line;
    }
  }
}
