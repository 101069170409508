@import "src/styles/mixins";
@import "src/styles/variables";

.container {
  width: 100%;
  padding: 4px 0;
  display: flex;
  align-items: flex-start;
  break-inside: avoid;
  page-break-inside: avoid;

  .buttons {
    display: flex;
    opacity: 0;
    flex-shrink: 1;
    margin-right: 4px;

    .increaseOrder,
    .decreaseOrder {
      padding: 0;
    }

    .decreaseOrder {
      img {
        transform: rotate(90deg);
      }
    }

    .increaseOrder {
      img {
        transform: rotate(-90deg);
      }
    }
  }

  &:hover {
    background: $hoverChildProjectElementsItem !important;

    .buttons {
      opacity: 1;
    }
  }

  .content {
    overflow: hidden;
    width: 100%;

    .header {
      display: flex;
      gap: 12px;

      .title {
        @include font($fontSize: 15, $fontWeight: 700);
        overflow: hidden;
        word-wrap: break-word;
        padding-bottom: 4px;
      }

      .estimate {
        @include font($grayTextColor, 13);
        white-space: nowrap;
        padding-bottom: 4px;
      }

      .actions {
        min-width: 20px;
        min-height: 20px;
        margin-left: auto;
        align-self: flex-start;

        & > button {
          padding: 2px;
        }
      }
    }

    .description {
      font-size: 13px;
      word-wrap: break-word;
      padding-right: 20px;
      white-space: break-spaces;
    }
  }
}
