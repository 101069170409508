@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 10px 0;
  margin: 0 12px;
  border-bottom: 1px solid $borderColor;

  .name {
    @include font($fontSize: 16, $fontWeight: 600);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}