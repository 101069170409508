@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  display: flex;
  justify-content: flex-end;
  padding: 15px;

  span {
    @include font($fontSize: 16, $fontWeight: 600);
  }
}