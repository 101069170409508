@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include changeImgVisibilityByImgState();

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    cursor: default;
    opacity: 0;
  }
}

.fill {
  border: 2px solid $elementsColor;
  border-radius: 100%;
}
