@import "src/styles/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  height: 32px;
  background: $white;
  -webkit-app-region: drag;
  border-bottom: 2px solid $borderColor;
  z-index: 1000;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: calc(100vw - 350px);

    span {
      font-weight: 600;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
