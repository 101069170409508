@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  min-width: 100vw;
  min-height: calc(100vh - var(--titleBarHeight, 0px));
  display: flex;
  align-items: center;
  justify-content: center;
  background: $elementsColor;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .buttonContainer {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      .button {
        width: 100%;
      }
    }
  }
}
