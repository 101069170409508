@import "src/styles/variables";
@import "src/styles/mixins";

.buttonsContainer {
  background: $white;
  height: 51px;
  display: flex;
  align-items: center;
  padding: 12px;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  @include customScroll();

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 400px;
  }
}

.loader {
  flex: 1,
}