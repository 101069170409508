@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  padding: 8px 4px 8px 8px;
  margin: 4px 6px;
  flex: 1;
  border: 1px solid $borderColor;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 51px;

  .content {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    margin-right: 4px;

    span {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name {
      @include font($fontSize: 13, $fontWeight: 600);
    }

    .value {
      @include font($grayTextColor, 12);
    }
  }
}

.hiddenContainer {
  margin: 2px;
  padding: 4px;
  flex-direction: column;
  position: relative;

  .content {
    margin-right: 0;
    margin-bottom: 4px;
  }
}

.draggingHidden {
  min-width: 288px;
  max-height: 53px;
}

.statusIconContainer {
  position: absolute;
  top: -6px;
  left: -6px;
  border-radius: 50%;
  padding: 2px;
  background: $white;
  border: 1px solid $elementsColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorStatus {
  border: 1px solid $errorColor;
}
