@import "src/styles/variables";
@import "src/styles/mixins";

.projectContainer {
  padding: 15px 15px 0 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  @include customScroll();

  .spacer {
    height: 15px;
    min-height: 15px;
    background: $projectBackgroundColor;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: $white;
    min-width: 620px;

    .contentFooter {
      min-height: 100px;
      background: red;
    }
  }
}

.loader {
  flex: 1;
}