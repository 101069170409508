@import "src/styles/mixins";
@import "src/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 270px;
  min-width: 270px;
  box-shadow: 4px 12px 22px rgba($color: #2b2c30, $alpha: 0.16);;
  border-right: 1px solid $borderColor;
}

.loader {
  flex: 1;
}