@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 35px;

  button {
    cursor: pointer;
  }

  .generalButton {
    min-width: 35px;
    min-height: 35px;
    background: $elementsColor;
  }

  .dropBoxContainer {
    width: max-content;
    display: none;
    z-index: 2;
    border: 1px solid $borderColor;
    border-radius: 3px;
    overflow-y: auto;
    background: white;
    box-shadow: $shadow;
    max-width: 400px;
    overflow: hidden;

    & > span {
      display: block;
      padding: 0 10px;
      margin-bottom: 10px;
    }

    .name {
      overflow: hidden;
      margin-top: 10px;
      @include font($elementsColor, 15, 700);
      text-overflow: ellipsis;
    }
    .email {
      overflow: hidden;
      @include font($grayTextColor);
      user-select: text;
      text-overflow: ellipsis;
    }

    .buttonsContainer {
      width: 100%;

      .button {
        border-top: 1px solid $borderColor;
        width: 100%;
        display: flex;
        padding: 10px;

        span {
          @include font($grayTextColor, 14, 600);
        }

        &:hover {
          background: $borderColor;

          span {
            @include font($mainTextColor, 14, 600);
          }
        }
      }
    }
  }

  .visibleDropBoxContainer {
    display: block;
  }
}
