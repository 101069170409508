@import "src/styles/mixins";

.loaderContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.fadeIn {
  @include fadeIn();
}
