@import "src/styles/variables";
@import "src/styles/mixins";

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(100vh - var(--titleBarHeight, 0px));
  @include fadeIn();
}

.main {
  flex: 1;
  overflow: hidden;
  display: flex;
}

.loader {
  flex: 1;
}