.container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .footer {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }
}