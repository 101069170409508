@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  @include getAuthPagesStyles();

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .desc {
      margin-top: 40px;
      @include font($grayTextColor);
      text-align: center;
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      .button {
        width: 100%;
      }
    }
  }
}
