@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    text-align: center;
    @include font($elementsColor, 14, 700);
  }

  &:hover {
    span {
      @include font($grayTextColor, 14, 700);
    }

  }

  &:disabled {
    cursor: default;
    span {
      @include font($borderColor, 14, 700);
    }
  }
}