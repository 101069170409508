@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    justify-content: space-between;
    padding: 24px;

    .column {
      display: flex;
      flex-direction: column;
      width: 50%;
      overflow: hidden;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 4px 0;
        display: block;
        @include font($fontSize: 16, $fontWeight: 600);
      }
    }

    .authorName {
      max-width: 250px;
    }
  }

  .tableHeaderRow {
    display: flex;

    div {
      border-top: 2px solid $borderColor;
      border-bottom: 1px solid $borderColor;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;

      span {
        @include font($fontSize: 16, $fontWeight: 700);
      }
    }

    .orderContainer {
      border-right: 1px solid $borderColor;
      max-width: 50px;
      min-width: 50px;
    }

    .tasksContainer {
      border-left: 1px solid $borderColor;
      border-right: 1px solid $borderColor;
      width: 100%;
    }

    .hoursContainer {
      border-left: 1px solid $borderColor;
      max-width: 90px;
      min-width: 90px;
    }
  }
}
