@import "src/styles/variables";
@import "src/styles/mixins";

.form {
  border: 2px solid $borderColor;
  min-width: 300px;
  max-width: 300px;
  padding: 15px;
  display: flex;
  min-height: 555px;
  flex-direction: column;
  background: $white;
  margin: auto;

  .formHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    .logo {
      display: flex;
      max-width: 200px;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 10px;
        @include font($elementsColor, 21, 700);
      }
    }

    .title {
      text-align: center;
      margin-top: 30px;
      @include font($grayTextColor, 16, 700);
    }
  }

  .formContent {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .infoContainer {
      margin-top: auto;
      overflow: hidden;
      min-height: 85px;
      padding-top: 20px;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .message {
      text-align: center;
      text-overflow: ellipsis;
      @include font($grayTextColor);
    }

    .successMessage {
      @include font($success);
    }
    .errorMessage {
      @include font($errorColor);
    }
  }

  .formFooter {
    border-top: 1px solid $borderColor;
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10;
  }
}
