$backgroundColor: white;
$white: white;
$success: #25871f;
$borderColor: #E2E6ED;
$elementsColor: #599ff3;
$grayTextColor: #8891A3;
$shadow: 0px 4px 22px rgba($color: #2b2c30, $alpha: 0.16);
$mainTextColor: #242737;
$projectBackgroundColor: #f3f1f1;
$hoverChildProjectElementsItem: whitesmoke;
$errorColor: #f54242;
