@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    @include font($fontSize: 14, $fontWeight: 600);
    margin-bottom: 4px;
  }

  textarea {
    @include customScroll();
  }

  input, textarea {
    border-radius: 3px;
    border: 2px solid $borderColor;
    padding: 6px 12px;
    width: 100%;
    resize: none;
    outline: none;
    @include font($fontSize: 13);
  }
}

.errorContainer {
  input, textarea {
    border-color: $errorColor;
  }
}