@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  padding: 12px 12px 10px 12px;
  border-bottom: 1px solid $borderColor;
  overflow: hidden;
  gap: 12px;

  .name {
    @include font($fontSize: 16, $fontWeight: 600);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  .iconButton {
    padding: 1px;
  }

  .loader {
    padding: 1px;
    width: 28px;
  }

  .multiplier {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: fit-content;

    span {
      font-weight: 400;
      margin: 0;
    }

    input {
      width: 75px;
    }
  }
}