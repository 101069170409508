@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  padding: 10px;
  background: rgba($color: #000000, $alpha: .6);
}

.body {
  background: $white;
  padding: 10px 10px 15px 10px;
  border-radius: 3px;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  @include customScroll();

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    span {
      @include font($fontSize: 16, $fontWeight: 700);
    }
  }
}