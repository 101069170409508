@import "src/styles/mixins";
@import "src/styles/variables";

.container {
  border-radius: 8px 8px 0 0;
  border-top: 2px solid $elementsColor;
  background: $white;
  overflow: hidden;
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 27px;
  max-height: 27px;
  width: 150px;
  min-width: 70px;

  .name {
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include font($fontSize: 13, $fontWeight: 600);
  }

  .statusClose {
    min-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5px;

    .dotIcon {
      margin: 0 4px;
      font-size: 9px;
    }

    .close {
      padding: 5px 0;
    }
  }
}

.active {
  background: $elementsColor;
  border-color: $elementsColor;

  .name {
    color: $white;
  }
}