@import "src/styles/mixins";
@import "src/styles/variables";

.container {
  display: flex;
  justify-content: space-between;
  background: $projectBackgroundColor;
  min-height: 37px;
  border-bottom: 2px solid $elementsColor;

  .droppableContainer {
    max-width: calc(100% - 28px);
    flex-grow: 1;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: flex-end;
    @include customScroll();
  }

  .home {
    min-width: 35px;
  }

  .activeHome {
    background: $elementsColor;
  }
}
